<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          Job #{{ job.jobNumber }}
          <span class="float-right">Job Card</span>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <p>
          <router-link :to="`/live-jobs/overview/${job.id}`">
            <v-btn small>Overview</v-btn>
          </router-link>
          &nbsp;
          <v-btn v-if="(jobUpdated > 1)" small @click="saveChanges">Save changes</v-btn>
          &nbsp;
          <router-link :to="`/live-jobs/job-card-print/${job.id}`">
            <v-btn small>Print preview</v-btn>
          </router-link>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col12">
        <table class="job-card-table">
            <thead>
            <tr>
                <td class="cell-border-top cell-border-left">Client Name:</td>
                <td class="cell-border-top cell-border-bottom cell-border-left">PO: {{ job.purchase_order }}</td>
                <td class="cell-border-top cell-border-left">Client Tel: {{ job.customerTel }}</td>
                <td class="cell-border-top cell-border-right">Riggotts Contact: {{ job.quotedBy }}</td>
            </tr>
            <tr>
                <td class="cell-border-bottom cell-border-left" >{{ job.customer }}</td>
                <td class="cell-border-bottom cell-border-left">Customer Job Ref: {{ job.customerReference }}</td>
                <td class="cell-border-left">Contact: {{ job.contact }}</td>
                <td class="cell-border-right cell-border-left">Site contact: {{ job.site_contact }}</td>
            </tr>
            <tr>
                <td class="cell-border-left">Site Name:</td>
                <td>Distance (One way) = {{ job.distance }} miles</td>
                <td class="cell-border-left cell-border-bottom">Contact Tel: {{ job.contactTel }}</td>
                <td class="cell-border-bottom cell-border-right cell-border-left">Site Tel: {{ job.site_tel }} </td>
            </tr>
            <tr>
                <td class="cell-border-left cell-border-bottom cell-border-right" colspan="4">{{ job.site }}</td>
            </tr>
            <tr>
                <td class="cell-border-bottom cell-border-left cell-border-right">
                    Start Date: {{ job.delivery_date | prettyDate }}
                </td>
                <td class="cell-border-bottom cell-border-right">
                    Start Time:
                </td>
                <td class="cell-border-bottom cell-border-right">Site Post Code: {{ job.postCode }}</td>
                <td class="cell-border-bottom cell-border-right">Site Email: {{ job.site_email }}</td>
            </tr>
            <tr>
                <td class="cell-border-bottom cell-border-left">Description of works and materials:</td>
                <td class="cell-border-bottom">Surface: {{ job.surfaceType }}</td>
                <td class="cell-border-bottom cell-border-left">Special Instructions: </td>
                <td class="cell-border-bottom cell-border-right"><strong>No of Visits Estimated: {{ job.days }}</strong></td>
            </tr>
            <tr>
                <td class="cell-border-bottom cell-border-left cell-fill-yellow">
                    <div v-html="job.description"></div>
                </td>
                <td class="cell-border-bottom">
                    Miles to Site (one-way) <span class="float-right">{{ job.distance }}</span><br />
                    Drive Time to Site <span class="float-right">{{ job.travel_hours }}</span><br />
                    Hours for Job <span class="float-right">{{ job.work_hours }}</span>
                </td>
                <td class="cell-border-bottom cell-border-left cell-border-right cell-fill-yellow" colspan="2">
                  <vue-editor
                    rows="3"
                    id="specialInstructions"
                    :editor-toolbar="customToolbar"
                    v-model="job.special_instructions" />
                </td>
            </tr>
            <tr>
              <td class="no-pad-cell" colspan="4">
                <table class="boq-table">
                  <thead>
                    <tr>
                      <th style="width: 60%" class="cell-border-bottom cell-border-left text-left">Bill of Quants:</th>
                      <th style="width: 20%" class="cell-border-bottom cell-border-left text-left">Units</th>
                      <th style="width: 20%" class="cell-border-bottom cell-border-left cell-border-right text-left">Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(section, sindex) in job.sections" :key="sindex">
                      <td style="padding:0" colspan="3">
                        <table style="width:100%;border-collapse:collapse">
                          <thead>
                            <tr>
                              <th class="cell-border-left cell-border-right cell-border-bottom text-left" colspan="3">
                                {{ section.title }}
                              </th>
                            </tr>
                            <tr v-for="(line, index) in section.lines" :key="index">
                              <td style="width: 60%" class="cell-border-left">
                                  {{ line.boq }} {{ line.colour }} {{ line.material }}
                              </td>
                              <td style="width:20%" class="cell-border-left">{{ line.unit }}</td>
                              <td style="width:20%" class="cell-border-left cell-border-right">{{ line.quantity }}</td>
                            </tr>
                          </thead>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            </thead>
            <tbody>
              <tr>
                  <td class="cell-border-bottom cell-border-left cell-border-top cell-border-right" colspan="4">
                      <strong class="text-red">
                          Please note! Any work undertaken over and above the items detailed in the BOQ MUST BE APPROVED BY Riggotts!
                      </strong>
                  </td>
              </tr>
            </tbody>
        </table>
      </v-col>
    </v-row>
    <v-row class="pt-5" v-if="job.documents">
      <v-col class="col-12">
        <h4>Documents attached to this quotation</h4>
      </v-col>
      <v-col
        class="col-12"
        v-for="(document, index) in job.documents"
        :key="index">
        <a
          :href="`https://riggotts.s3.eu-west-2.amazonaws.com/original/${document.uri}`"
          target="_blank">
          View: {{ document.uri.substring(document.uri.indexOf("__") + 2) }}
        </a>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="showMessage = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from '../../axios';

export default {
  name: 'LiveJobView',
  components: {
    VueEditor,
  },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline", ],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      job: {},
      message: '',
      showMessage: false,
      jobUpdated: 0,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  watch: {
    job: {
      handler() {
        this.jobUpdated += 1;
      },
      deep: true,
    },
  },
  methods: {
    getJob() {
      axios.get(`/liveJobs/getSingle/${this.$route.params.id}.json?token=${this.token}`)
      .then((response) => {
        this.job = response.data;
      });
    },
    saveChanges() {
      const postData = {};
      postData.job = this.job.id;
      postData.specialInstructions = this.job.special_instructions;
      axios.post(`/liveJobs/saveSpecialInstructions/${this.$route.params.id}.json?token=${this.token}`, postData)
        .then((response) => {
          this.message = response.data.message;
          this.showMessage = true;
        });
    },
  },
  mounted() {
    this.getJob();
  },
};
</script>
